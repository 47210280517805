export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [],
    },

    aside: {
      self: {},
      items: [
        {
          title: 'Cockpit',
          root: true,
          icon: 'flaticon2-pie-chart-1',
          bullet: 'dot',
          page: '/app/cockpit/dashboard',
          roles: ['bo-admin', 'bo-wl-admin'],
        },
        /* {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/app/dashboard',
          translate: 'MENU.DASHBOARD',
          bullet: 'dot',
          roles: ['bo-admin', 'bo-wl-admin'],
        }, */
        {
          title: 'P&L',
          root: true,
          icon: 'flaticon2-chart',
          page: '/app/implantation/p-and-l',
          translate: 'MENU.DASHBOARD',
          bullet: 'dot',
          roles: ['bo-lead'],
        },
        {
          title: 'Implantação',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-open-box',
          roles: ['bo-admin'],
          submenu: [
            {
              title: 'Adicionar projetos',
              page: '/app/implantation/step-one',
              roles: ['bo-admin'],
              //    translate: 'MENU.DASHBOARD',
            },
            {
              title: 'Listar projetos',
              page: '/app/implantation/projects',
              roles: ['bo-admin'],
              //    translate: 'MENU.DASHBOARD',
            },
            {
              title: 'Gestão de menu',
              root: true,
              bullet: 'dot',
              roles: ['bo-admin'],
              //    translate: 'MENU.DASHBOARD',
              submenu: [
                {
                  title: 'Menu',
                  page: '/app/implantation/menu-list',
                  roles: ['bo-admin'],
                },
                {
                  title: 'Permissões',
                  page: '/app/implantation/menu-roles',
                  roles: ['bo-admin'],
                },
              ],

            }
          ],
        },
        {
          title: 'Prospera',
          root: true,
          bullet: 'dot',
          icon: 'flaticon-light',
          roles: ['bo-ppr-admin'],
          submenu: [
            {
              title: 'Dashboard',
              root: true,
              bullet: 'dot',
              page: '/app/prosperaenergy-management/dashboard',
              roles: ['bo-ppr-admin'],
            },
            {
              title: 'Controle de Clientes',
              roles: ['bo-ppr-admin'],
              submenu: [
                {
                  title: 'Análise',
                  page: '/app/prosperaenergy-management/review',
                  roles: ['bo-ppr-admin'],
                },
                {
                  title: 'Aprovados',
                  page: '/app/prosperaenergy-management/approved',
                  roles: ['bo-ppr-admin'],
                },
                {
                  title: 'Reprovados',
                  page: '/app/prosperaenergy-management/not-approved',
                  roles: ['bo-ppr-admin'],
                },
                {
                  title: 'Onboarding',
                  page: '/app/prosperaenergy-management/onboarding',
                  roles: ['bo-ppr-admin'],
                },
              ],
            },
            {
              title: 'Gestão de Cashback',
              root: true,
              bullet: 'dot',
              page: '/app/prosperaenergy-management/cashback-acquisition',
              roles: ['bo-ppr-admin'],
            },
            {
              title: 'Gestão de Contratos',
              root: true,
              bullet: 'dot',
              page: '/app/prosperaenergy-management/contract/list',
              roles: ['bo-ppr-admin'],
            },
            {
              title: 'Gestão de Energia',
              root: true,
              bullet: 'dot',
              page: '/app/prosperaenergy-management/energy-availability',
              roles: ['bo-ppr-admin'],
            },
            {
              title: 'Gestão de Ranges',
              root: true,
              bullet: 'dot',
              page: '/app/prosperaenergy-management/ranges',
              roles: ['bo-ppr-admin'],
            },
            {
              title: 'Gestão de Prazos',
              roles: ['bo-ppr-admin'],
              submenu: [
                {
                  title: 'Cadastro dos Prazos',
                  page: '/app/prosperaenergy-management/deadline',
                  roles: ['bo-ppr-admin'],
                },
                {
                  title: 'Configuração dos Prazos',
                  page: '/app/prosperaenergy-management/deadline-config',
                  roles: ['bo-ppr-admin'],
                },
              ],
            },
            {
              title: 'Gestão de Tarifas',
              root: true,
              bullet: 'dot',
              page: '/app/prosperaenergy-management/rates',
              roles: ['bo-ppr-admin'],
            },
          ],
        },
        {
          title: 'Financeiro',
          root: true,
          bullet: 'dot',
          icon: 'flaticon-price-tag',
          roles: ['bo-admin'],
          submenu: [
            {
              title: 'Taxas e tarifas',
              page: '/app/tax-management/parameter',
              roles: ['bo-admin'],
            },
            {
              title: 'Manutenção de Range',
              page: '/app/tax-management/range-control',
              roles: ['bo-admin'],
            },
            // {
            //   title: 'Lançamento Manual',
            //   page: '/app/tax-management/manual-rescue',
            //   roles: ['bo-manual-rescue'],
            // },
            // {
            //   title: 'Monitoramento de devolução de pagamento',
            //   page: '/app/financial/payment-devolution',
            //   roles: ['bo-admin'],
            // },
            // {
            //   title: 'Controle de emissão de boleto e TED',
            //   page: '/app/financial/control-billet-ted',
            //   roles: ['bo-admin'],
            // },
            // {
            //   title: 'Monitoramento de extrato',
            //   page: '/app/financial/extract-monitoring',
            //   roles: ['bo-admin'],
            // },
          ],
        },
        {
          title: 'Gestão de Cliente',
          root: true,
          icon: 'flaticon-customer',
          bullet: 'dot',
          page: '/app/account/customer',
          roles: ['bo-admin', 'bo-wl-admin'],
        },
        {
          title: 'Gestão de Onboarding',
          root: true,
          icon: 'flaticon-network',
          bullet: 'dot',
          page: '/app/account/onboarding',
          roles: ['bo-admin', 'bo-wl-admin'],
        },
        {
          title: 'Importação de Onboarding',
          root: true,
          icon: 'flaticon-network',
          bullet: 'dot',
          page: '/app/account/onboarding/importation',
          roles: ['bo-admin'],
        },
        {
          title: 'Gestão de Cnab',
          root: true,
          icon: 'flaticon-folder-1',
          bullet: 'dot',
          page: '/app/cnab-management',
          roles: ['bo-admin', 'bo-wl-admin'],
        },
        {
          title: 'Cartões',
          root: true,
          icon: 'flaticon2-browser',
          bullet: 'dot',
          roles: ['bo-admin'],
          submenu: [
            {
              title: 'Gestão de Cartões',
              page: '/app/cards-management/cards-details',
              roles: ['bo-admin'],
            }
          ],
        },
        {
          title: 'Gestão Escrow',
          root: true,
          icon: 'flaticon2-group',
          bullet: 'dot',
          roles: ['bo-admin'],
          submenu: [
            {
              title: 'Criar Agente Escrow',
              page: '/app/escrow-management/create-agent-escrow',
              roles: ['bo-admin', 'bo-wl-admin'],
            },
            {
              title: 'Gerenciar Vínculos Escrow',
              page: '/app/escrow-management/manage-escrow-links',
              roles: ['bo-admin'],
            },
            {
              title: 'Gerenciar Agentes Escrow',
              page: '/app/escrow-management/manage-escrow-agents',
              roles: ['bo-admin', 'bo-wl-admin'],
            },
          ],
        },
        {
          title: 'Transações',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-chart',
          roles: ['bo-admin'],
          submenu: [
            {
              title: 'Transações Recusadas',
              page: '/app/account/declined-transactions',
              roles: ['bo-admin'],
            },
            {
              title: 'Não Compensada',
              page: '/app/account/not-compensated',
              roles: ['bo-admin'],
            },
            {
              title: 'Busca de Comprovantes',
              page: '/app/account/voucher-search',
              roles: ['bo-admin'],
            },
          ],
        },
        {
          title: 'Gestão de Liquidação',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-chart',
          roles: ['bo-admin'],
          submenu: [
            {
              title: 'Liquidações de boletos',
              page: '/app/liquidation/billet',
              roles: ['bo-admin'],
            },
            {
              title: 'Lotes de boletos baixados e liquidados',
              page: '/app/liquidation/billet/lot',
              roles: ['bo-admin'],
            },
          ],
        },

        // {
        //   title: 'Risco e compliance',
        //   root: true,
        //   bullet: 'dot',
        //   icon: 'flaticon-security',
        //   roles: ['bo-admin'],
        //   submenu: [
        //     {
        //       title: 'Critérios',
        //       page: 'ngbootstrap/accordion',
        //       roles: ['bo-admin'],
        //     },
        //     {
        //       title: 'Monitoramento de limites',
        //       page: '/app/risk-management',
        //       roles: ['bo-admin'],
        //     },
        //   ],
        // },
        // {
        //   title: 'Produtos e serviços',
        //   root: true,
        //   bullet: 'dot',
        //   icon: 'flaticon-layer',
        //   page: 'ngbootstrap/accordion',
        //   roles: ['bo-admin'],
        // },
        // {
        //   title: 'Adquirência e MDR',
        //   root: true,
        //   bullet: 'dot',
        //   icon: 'flaticon2-list',
        //   page: 'ngbootstrap/accordion',
        //   roles: ['bo-admin'],
        // },
        {
          title: 'Gestão de atendimento',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-digital-marketing',
          page: '/app/attendances-management/attendances',
          roles: ['bo-admin'],
        },
        {
          title: 'Gestão de Bloqueios',
          root: true,
          icon: 'flaticon-warning-sign',
          page: '/app/implantation/panic',
          roles: ['bo-manager-panic'],
        },
        // {
        //   title: 'Gestão de usuário',
        //   root: true,
        //   bullet: 'dot',
        //   icon: 'flaticon2-user-outline-symbol',
        //   roles: ['bo-admin'],
        //   submenu: [
        //     {
        //       title: 'Usuários',
        //       page: 'users/all',
        //       roles: ['bo-admin'],
        //     },
        //     {
        //       title: 'Permissões',
        //       page: 'users/roles',
        //       roles: ['bo-admin'],
        //     },
        //   ],
        // },
        {
          title: 'Tesouraria',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-user-outline-symbol',
          roles: ['bo-admin', 'bo-wl-admin'],
          submenu: [
            {
              title: 'Painel de Monitoramento',
              page: '/app/treasury/panel-treasury',
              roles: ['bo-admin', 'bo-wl-admin'],
            },
          ],
        },
        {
          title: 'Administração PIX',
          root: true,
          icon: 'flaticon-warning-sign',
          page: '/app/med-pix-management/dashboard',
          bullet: 'dot',
          roles: ['bo-admin'],
          submenu: [
            {
              title: 'Relato de infração / MED',
              page: '/app/med-pix-management/dashboard',
              roles: ['bo-admin']
            }
          ]
        },
      ],
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
